<template>
  <div class="viewfnwj">
    <div>
      阅读或近距离工作时您是否觉得眼部疲劳或不适
      <el-radio v-model="radio" label="1">备选项</el-radio>
      <el-radio v-model="radio" label="2">备选项</el-radio>
    </div>
  </div>
</template>

<script>
export default {
  name: "viewfnwj",
  data() {
    return {
      list: []
    }
  }
}
</script>

<style scoped lang="scss">
 .viewfnwj {
   height: 76vh;
   overflow-y: auto;
   background: #ececec;
 }

</style>
